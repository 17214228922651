import { catchError, finalize, map, of } from 'rxjs';

import { AuthLoaderService } from './auth-loader.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RedirectService } from '../../shared/services/redirect.service';
import { Roles } from '../../shared/models/roles';
import { Router } from '@angular/router';
import { TenantOptions } from '../../shared/models/tenant-options';
import { ToastService } from '../../shared/services/toast.service';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_URL = environment.apiUrl;
  private readonly LOGIN_URL = environment.loginURL;
  private readonly TM_PANEL_URL = environment.tmPanel;

  public redirectUrl: string | null = null;

  constructor(
    private http: HttpClient,
    private authLoaderService: AuthLoaderService,
    private toastService: ToastService,
    private router: Router,
    private redirectService: RedirectService
  ) {}

  public login(email: string, password: string) {
    this.authLoaderService.setLoading(true);

    return this.http.post(`${this.API_URL}/login`, { email, password }).pipe(
      map((response: any) => this.handlerSuccess(response)),
      catchError((error) => this.handlerError(error)),
      finalize(() => this.authLoaderService.setLoading(false))
    );
  }

  private handlerSuccess(response: any) {
    const redirectTo = this.redirectUrl || '/';

    localStorage.setItem('authToken', response.token);

    delete response.token;

    localStorage.setItem('user', JSON.stringify(response));
    this.toastService.show('success', 'Login efetuado com sucesso');
    this.router.navigate([redirectTo]);

    return response;
  }

  private handlerError(error: any) {
    const message = error.error.message || 'Erro no login';
    this.toastService.show('error', message);

    return error;
  }

  public isLoggedIn() {
    return !!localStorage.getItem('user');
  }

  public logout(redirectURL?: string): void {
    localStorage.clear();

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('token');

    const redirectUrl = redirectURL
      ? `${this.LOGIN_URL}?redirect=${redirectURL}`
      : `${this.TM_PANEL_URL}/logout`;

    window.location.href = `${this.TM_PANEL_URL}/logout`;
  }

  public getUserInfo(): User {
    return JSON.parse(localStorage.getItem('user') as string);
  }

  public getUserRoles(): Roles[] {
    const user = this.getUserInfo();
    return user.roles;
  }

  public getUserTenantOptions(): TenantOptions[] {
    const user = this.getUserInfo();
    return user.tenant_options.enabled_features;
  }

  public hasRole(role: Roles): boolean {
    return this.getUserRoles().includes(role as Roles);
  }

  public hasTenantOption(tenantOption: TenantOptions): boolean {
    return this.getUserTenantOptions().includes(tenantOption as TenantOptions);
  }

  public getUserData(redirectURL?: string) {
    this.authLoaderService.setLoading(true);

    return this.http.get(`${this.API_URL}/login/my_data`).pipe(
      map((response) => {
        // MOCK para teste de permissões
        // const testResponse = {
        //   id: '4a3651a5-f388-4819-a763-f013742acf04',
        //   name: 'Brunelli Cupello',
        //   email: 'brunelli.cupello@terramagna.com.br',
        //   roles: [
        //     'ADMIN',
        //     'MANAGER',
        //     'ANALYST',
        //     'OPERATION_REQUESTER',
        //     'OPERATION_REGISTER',
        //     'SALESMAN',
        //     'SALES_ADMIN',
        //     'CREDIT_ANALYST',
        //     'CREDIT_MANAGER',
        //     'CARTOGRAPHY_REVIEWER',
        //     'CARTOGRAPHY_ANALYST',
        //     'CUSTOMER_SUCCESS',
        //     'CUSTOMER_SUCCESS_REGISTER',
        //     'CREDIT_RISK_ANALYST',
        //     'CREDIT_LEGAL_RISK_ANALYST',
        //     'CREDIT_RISK_ANALYSIS_REQUESTER',
        //     'BUSINESS_GROUPS_MANAGER',
        //     'CREDIT_ENTITLEMENTS_DESPATCHER',
        //     'CREDIT_ENTITLEMENTS_MANAGER',
        //     'CREDIT_PORTFOLIOS_VIEWER',
        //     'CREDIT_PORTFOLIOS_MANAGER',
        //     'PROFILE_VIEWER',
        //     'PROFILE_MANAGER',
        //     'SERPRO_VIEWER',
        //     'SERPRO_SUBMITTER',
        //     'PARTNER_ACE',
        //     'FARMER_ANALYSIS_MANAGER',
        //   ],
        //   client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //   client: {
        //     id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //     name: 'terramagna',
        //     pretty_name: 'Terramagna',
        //     cnpj: '97044943000158',
        //     creation_time: '2020-03-12T14:28:39.286673Z',
        //   },
        //   tenant_options: {
        //     enabled_features: [
        //       'credit.rating',
        //       'credit.tradeBill',
        //       'credit.instantCredit',
        //       'credit.checkup',
        //       'credit.newCreditAnalysis',
        //       'credit.producerManagement',
        //       'billingAnalyst.prefinLight',
        //       'riskManagement.monitoredOperations',
        //       'credit.newEndorsed',
        //       'riskManagement.operationsRegister',
        //       'billingAnalyst.newDashboard',
        //       'riskManagement.historicalAnalysis',
        //       'riskManagement.areaAnalysis',
        //       'credit.easyCreditSimulation',
        //       'credit.producerCreditAnalyses',
        //       'credit.proposalManagement',
        //       'credit.cprfWithoutPledge',
        //       'credit.easyCredit',
        //       'credit.payer',
        //       'credit.walletReport',
        //       'credit.cprfWithPledge',
        //       'credit.registration',
        //       'platform.headerBanner',
        //       'credit.composeCollateral',
        //       'operation.notification',
        //       'billingAnalyst.prefinLite',
        //       'credit.promissoryNote',
        //       'credit.agrogalaxyNP',
        //     ],
        //     disabled_features: [],
        //   },
        //   tenant_divisions: [
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:53.477038Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '012f59eb-1b73-4b01-93dc-4b836807cdf9',
        //       name: 'Cresol Goiás',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-02-14T15:32:52.044650Z',
        //       creator_id: 'b80e16a4-b44b-40ee-bdb7-89957c7a603e',
        //       id: 'd7ea22aa-865e-4bcb-b649-3d78e7a54963',
        //       name: 'teste_1234',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-27T14:31:34.003133Z',
        //       creator_id: '8e077a46-614d-44a4-ade6-2aa2e7eb6b4d',
        //       id: 'b318cf3d-91b3-41ee-964c-4988797e6b87',
        //       name: 'Cartografia',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T13:44:25.061583Z',
        //       creator_id: '730e0fff-d7dd-44e8-82d7-4b448a0b4916',
        //       id: '1b0cbea4-deb4-4c90-b7bc-c92706a9358d',
        //       name: 'QA',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:55.067235Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'dcd2e460-7fb7-4a90-907d-4ceb2caa4f70',
        //       name: 'Cresol Vale Europeu',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:55.882704Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '78a4bdcf-072b-4e2b-99af-70d811995eae',
        //       name: 'Cresol União dos Pinhais',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-30T18:17:23.295184Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: '1c9bd834-c1a9-4405-a6db-ecbb9f69b52d',
        //       name: 'SELECT * FROM USERS',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:56.292227Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '12c774f6-ae7a-4e7d-9624-500976e4375f',
        //       name: 'Cresol Norte Paranaense',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-08-14T18:29:50.057662Z',
        //       creator_id: 'cea88b29-a6ac-4db5-b877-f5429302700a',
        //       id: 'cfecc79c-d72b-454e-978f-e9ddc55317c1',
        //       name: 'Testão',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:50.141670Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '317d6145-4d9e-4262-b459-6b5943b9aee1',
        //       name: 'Cresol Integracao',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-02-13T12:05:57.655015Z',
        //       creator_id: 'd61c20c8-3a06-41cd-826e-2669e4cdc175',
        //       id: '3f2fe0e1-7119-4538-84dd-f7b7023192be',
        //       name: 'MATOPIBA',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:53.231777Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'dee2f523-d042-4b07-a1f7-2b8def62c57d',
        //       name: 'Cresol Rondônia',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:50.347681Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '214bc210-38af-40b0-a43d-a3976a3df250',
        //       name: 'Cresol Fronteira PR',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T14:25:42.522913Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: '1b2f6537-a87d-4a24-bee3-2f13be673021',
        //       name: 'Divisao 11',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T14:44:10.097561Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: '8d0ee71b-2bc6-4fe3-a939-75877928d4c0',
        //       name: 'Filial 01',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:51.705938Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'ecb02843-6bc5-4b1b-af92-9d394d0a4467',
        //       name: 'Cresol Vale das Araucárias',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2022-03-03T19:48:35.810171Z',
        //       creator_id: 'c779a41f-deb0-4a3e-8891-79fa26826b7f',
        //       id: 'f4c32bd9-e736-414c-98cf-5f6493a13b59',
        //       name: 'Teste 123456',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-03-23T18:27:29.696816Z',
        //       creator_id: '6ac906c9-a8ba-486b-9777-cc4936d45d56',
        //       id: '3ceb8102-aa83-4140-a4b6-01949a216b0d',
        //       name: 'teste2',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:53.832261Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '87c4a403-ce76-4e52-b01b-d3a0de359dcf',
        //       name: 'Cresol Tradição',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2020-03-18T13:13:04.437944Z',
        //       creator_id: '2e0999ff-6bc5-4d5b-b4b3-f3d521ae72ac',
        //       id: '2a51b1b6-ae41-435c-8047-92900cfea411',
        //       name: ' nb,/',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:49.335488Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '9914beec-cd9f-43ad-bf33-90ab98369476',
        //       name: 'Cresol Vale das Águas',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-30T18:17:50.223563Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: 'bf28630c-2126-4103-9ec2-1fa4798bf69a',
        //       name: 'SELECT *',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:54.043010Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'feeeb4d6-b534-4eff-badb-19f1e189b207',
        //       name: 'Cresol União dos Vales',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-02-22T18:30:46.812358Z',
        //       creator_id: '5c8d8181-a88d-4787-9a2b-7819cb882ad5',
        //       id: 'fe285591-c98a-437e-9f8e-80a18a01304c',
        //       name: 'Suporte interno',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:54.242205Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '9ed57076-248c-4746-b5ed-73df4b71bf56',
        //       name: 'Cresol Costa Oeste',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T14:23:49.624149Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: 'a258fe98-428c-44b3-8544-893ee0428253',
        //       name: 'Divisão 001',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2022-10-25T20:15:30.344789Z',
        //       creator_id: 'bf60692b-fa07-417f-821e-51306712f9db',
        //       id: 'b9be1802-e4cf-4604-8730-41366fd12a32',
        //       name: 'teste dash2',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T14:29:21.440720Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: '044bea8a-00d1-4587-a37a-9356efbda7ea',
        //       name: 'Goiás',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:55.677270Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '96e95998-b3a4-4751-9ce9-a910e2cd0186',
        //       name: 'Cresol Triunfo',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:56.086903Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '1ce60052-be46-4931-a22c-d96e299c00de',
        //       name: 'Cresol Oeste Catarinense',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-03-21T23:29:54.676471Z',
        //       creator_id: '5527aae1-1fcd-4e69-a75b-d6fba83fc345',
        //       id: '9523cdaa-1add-4e7a-984b-eddc4f8820ea',
        //       name: 'JavaScripteiros',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:54.424976Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'fba27a73-5575-4190-a91a-1392a9e1efaa',
        //       name: 'Cresol Sul Paranaense',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T14:26:08.055110Z',
        //       creator_id: 'dfccbd91-d63c-4e4b-a689-6a1b65a934fb',
        //       id: '5a143ad5-d4c3-4cd8-a09f-6eb9520e3720',
        //       name: 'Coordenador ; Márcio Luiz Cavalli',
        //       users_count: 6,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:52.702116Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'c23dc959-b05a-4c2f-8856-db60f9563d5b',
        //       name: 'Cresol Zona da Mata',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:52.891078Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '79f91251-b47e-4eb9-8fd8-f56e848f3641',
        //       name: 'Cresol Sul',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-02-13T12:07:13.088549Z',
        //       creator_id: 'd61c20c8-3a06-41cd-826e-2669e4cdc175',
        //       id: '8dc39162-b73d-44b1-a8c2-1a497e4578ae',
        //       name: 'matopiba',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T14:19:25.224940Z',
        //       creator_id: 'dfccbd91-d63c-4e4b-a689-6a1b65a934fb',
        //       id: '4ac962c2-ec1a-4fb0-ae02-f4b6466dc1c7',
        //       name: 'Coordenador ; Eduardo Marcos Tomaz',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-02-06T13:53:20.623166Z',
        //       creator_id: '4b5a0dd2-fdc2-43d7-aa4f-064bdcf5a7c8',
        //       id: '2b59f0ba-4740-4291-935c-dbf799d4663e',
        //       name: 'Teste Divisão 1',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-02-04T22:04:39.117534Z',
        //       creator_id: '4b5a0dd2-fdc2-43d7-aa4f-064bdcf5a7c8',
        //       id: 'dc0784ac-5054-4f14-a0e9-1f1f878f5094',
        //       name: 'Teste 12',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T14:22:04.955650Z',
        //       creator_id: 'dfccbd91-d63c-4e4b-a689-6a1b65a934fb',
        //       id: 'd87af6ed-aeed-4ea3-9bc2-b294bf70d38a',
        //       name: 'Coordenador ; Herbert Evangelista dos Santos',
        //       users_count: 5,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T14:31:31.875191Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: '710b1320-a467-42c9-a023-4160192456f8',
        //       name: 'Cuiabá',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:49.737235Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '3ac6aedc-2837-4308-9c89-16fc0223b6d5',
        //       name: 'Cresol Progresso',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:51.509367Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '662842b9-e9f5-4cd1-ad0c-bd1544a57d32',
        //       name: 'Cresol Nordeste',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T14:28:46.133567Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: '18a50048-eda4-4808-ba56-2a657b5e15ed',
        //       name: 'Filial 03',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-08T13:20:28.584505Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: '3a7b410d-c6b6-491f-b224-f884cba059b9',
        //       name: 'Filial 02',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:54.652827Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '5b067f51-b6bf-40d5-aa87-30166303ff18',
        //       name: 'Cresol Noroeste Capixaba',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-19T14:17:06.063048Z',
        //       creator_id: '16e72de4-715e-4abb-9898-be46f4f7c5c0',
        //       id: 'da82e62c-1dfa-4022-b56e-1f510d07ea8c',
        //       name: 'Divião Bahia',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-22T14:47:34.540124Z',
        //       creator_id: 'c1f24edc-3478-447a-949b-3d2f34f1bec4',
        //       id: '4b64fa41-0299-43cf-b37e-324a5b36ccbd',
        //       name: 'Desenvolvedores',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-12-15T20:12:28.682341Z',
        //       creator_id: 'cea88b29-a6ac-4db5-b877-f5429302700a',
        //       id: '4bea3fad-307a-4f64-8833-753df357ed4b',
        //       name: 'Teste 123',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2023-02-06T12:27:00.596462Z',
        //       creator_id: '4b5a0dd2-fdc2-43d7-aa4f-064bdcf5a7c8',
        //       id: '1aaa0cd9-1d07-4ec2-91e5-58ade9651f56',
        //       name: 'Teste XYZ',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-07-14T15:53:14.143328Z',
        //       creator_id: '6ac906c9-a8ba-486b-9777-cc4936d45d56',
        //       id: '7f173e35-a08e-44a9-a1f9-772eaefb73ab',
        //       name: 'Teste divisão pré QA',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2021-11-30T14:23:17.720758Z',
        //       creator_id: 'c779a41f-deb0-4a3e-8891-79fa26826b7f',
        //       id: 'bd12c851-e014-4333-8b06-896f25a4d4d4',
        //       name: 'Ultra critica 2',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2020-04-08T23:07:28.906063Z',
        //       creator_id: '2e0999ff-6bc5-4d5b-b4b3-f3d521ae72ac',
        //       id: 'c7a4eae4-4140-46d9-904b-154b9f5c8074',
        //       name: 'teste',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:54.858353Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'da39b972-0f1a-4379-8c20-e03884036c1e',
        //       name: 'Cresol Liderança',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:49.938292Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: 'ed5dfd2e-705c-412a-a2e5-8cf77ed05d5a',
        //       name: 'Cresol Sudeste de Minas',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2024-10-23T12:02:18.814023Z',
        //       creator_id: 'f7c69c0d-5ded-4589-b103-17516a73781b',
        //       id: '580572c1-fbf0-4957-bd67-7818d831aca7',
        //       name: 'Teste Divisão Luciano',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T09:10:55.271926Z',
        //       creator_id: '59064363-fe44-4f3e-b136-c0d2832746f8',
        //       id: '78b893ee-fbc4-4bfa-b2b1-d5411a12cae2',
        //       name: 'Cresol Pioneira',
        //       users_count: 0,
        //     },
        //     {
        //       client_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //       creation_time: '2019-10-09T14:12:39.642804Z',
        //       creator_id: 'dfccbd91-d63c-4e4b-a689-6a1b65a934fb',
        //       id: '02ed57d0-73a0-4774-ab51-646b8f9bf6ae',
        //       name: 'a',
        //       users_count: 0,
        //     },
        //   ],
        //   tenant_id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //   tenant: {
        //     id: '1581e7df-e4af-47e1-b9d2-2fe16474c5d7',
        //     name: 'terramagna',
        //     pretty_name: 'Terramagna',
        //     cnpj: '97044943000158',
        //     creation_time: '2020-03-12T14:28:39.286673Z',
        //   },
        // };

        localStorage.setItem('user', JSON.stringify(response));

        this.redirectService.redirectToCurrentRouteWithoutQueryParams();
        return true;
      }),
      catchError(() => {
        this.toastService.show('error', 'Erro ao buscar dados do usuário');
        this.logout(redirectURL);
        return of(false);
      })
    );
  }
}
